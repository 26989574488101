<template>
  <BooksIcon name="checkmark" status="muted" hidden-text="saved" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UtilitySaved',
});
</script>
