import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

export default (app: App, router: Router) => {
  if (window.CONFIG?.SENTRY_DSN) {
    window.Sentry = Sentry;

    // Configure Sentry
    Sentry.init({
      app,
      dsn: window.CONFIG.SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.vueIntegration({ tracingOptions: { trackComponents: true } }),
      ],
      tracesSampleRate: window.CONFIG.SENTRY_SAMPLE_RATE || 0.1,
    });
  }
};
